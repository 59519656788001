.fondTableauBord {
	background-color: #f7eceb;
	min-height:100%;
	padding-bottom: 10%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(/images/fondcarre.png);
	background-size: 380px;
	background-position: bottom -8% right -8%;
}
.tableauBordContainer {
	margin-top: 5%;
}
.textTdb {
	margin: 0 auto;
	max-width: 50%;
}

.tableauBordContainer h1{
	text-align: center;
}

.tableauBordContainer p{
	text-align: center;
}

@media only screen and (max-width: 480px) {
	.tableauBordContainer h1 {
		margin: 5%;
	}
	.tableauBordContainer span {
		margin: 5%;
	}
	.fondTableauBord {
		background: none;
	}
	.textTdb {
		margin: 0 auto;
		max-width: 100%;
	}
}
