.introContainer {
	margin-top: 5%;
}
.fondIntro {
	background-color: #f7eceb;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(/images/fondcarre.png);
	background-size: 380px;
	min-height: 85vh;
	background-position: bottom -8% right -8%;
}

@media only screen and (max-width: 480px) {
	.introContainer {
		max-width: 100%;
		text-align: center;
		margin: auto;
	}
	.fondIntro {
		background-size: 300px;
		background-position: bottom 0% right -100%;
		min-height: 100vh;
	}
}
