.fondEdito {
	background-image: url(/images/fond_edito_mobile.png);
	min-height:100%;
	padding-bottom: 10%;
	background-repeat: no-repeat;
	background-size: cover;
	color: white;
}
.fondEdito p {
	margin: 5%;
}
.editoContainer {
	margin-top: 5%;
}

.editoContainer .text{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}

.editoContainer .text-wrapper{
	width: 50%;
	font-size: 16px;
}

.editoContainer .surveyResumeText{
text-align: center;
margin:0
}

@media only screen and (max-width: 480px) {
	.fondEdito p {
		color: white;
		margin: 5%;
		margin-top: -3%;
		// padding-bottom: 5%;
		font-size: 16px;
	}

	.fondEdito h1 {
		margin: 5%;
	}
	.editoContainer .text-wrapper{
		width: 80%;
		text-align: justify;
	}
}
