.fondQuestionnaire {
	background-color: #f7eceb;
	min-height:100%;
	padding-bottom: 10%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(/images/fondcarre.png);
	background-size: 300px;
	background-position: bottom 5% right 0%;
}

.fondQuestionnaire h1 {
	margin-top: 2%;
	margin-bottom: 2%;
	
}

.ProgressBar {
	padding-top: 2%;
	width: 60%;
	margin: auto;
}

.fondBlanc {
	padding-bottom: 5%;
}

.Button {
	padding: 10%;
}
.fondSurvey {
	padding: 20px;
	margin: 15px auto 0 auto;
	border-radius: 6px;
}


.fondQuestionnaire .questions-navigation-wrapper{
	font-weight: bold;
	color:#26305E;
	font-size: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 50%;
}


.fondQuestionnaire .questions-navigation-wrapper a{
	cursor: pointer;
}

.fondQuestionnaire .questions-navigation-wrapper a:hover{
	cursor: pointer;
	color:#191f3b
}

.fondQuestionnaire .questions-navigation-wrapper .right-arrow-disable {
	cursor: not-allowed;
		color:grey

}

.fondQuestionnaire .questions-navigation-wrapper .right-arrow-disable:hover{
	cursor: not-allowed;
	color:grey
}

.openQuestion-wrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.openQuestion-wrapper h3{
	margin-top:50px;
	width: 100%;   
	 font-family: "Century Gothic";
    font-weight: bold;
    font-size: 17px;
    line-height: 1.167;
    color: rgb(38, 48, 94);
	text-align: center;
}

.openQuestion-wrapper textarea{
	width: 50%;
	min-width: 50%;
	min-height: 150px;
	max-height: 300px;
	max-width: 70%;
	font-family: "Century Gothic";
	padding:10px;
	border:2px solid #C77D00;
}




@media only screen and (max-width: 480px) {
	.ProgressBar {
		padding-top: 10%;
		width: 80%;
	}
	.fondQuestionnaire h3 {
		padding-top: 5%;
	}
	.fondQuestionnaire {
		background-color: #f7eceb;
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(/images/fondcarre.png);
		background-size: 300px;
		background-position: bottom -10% right -50%;
	}

	.fondQuestionnaire .questions-navigation-wrapper{
		font-weight: bold;
		color:#26305E;
		font-size: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 85%;
		margin-top:25px;
	}
	

}
