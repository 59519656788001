.popup-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.425);
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-wrapper .content-wrapper {
	background-color: white;
	width: 500px;
	height: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.popup-wrapper .content-wrapper p {
	text-align: center;
	width: 100%;
	//margin: 0px 20px 0px 20px;
}

.heading {
	font-family: 'Century Gothic';
	text-align: center;
	font-size: 1.5rem;
	color: #707070;
	font-weight: 100;
	margin-bottom: 20px;
	margin-top: 20px;
}
