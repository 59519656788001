.App {
	height: 100%;
}

/* h1,
h2,
h3,
p {
	text-align: left;
} */
body {
	background-color: #f7eceb;
}
/************************************************************************************************/
/***********************************************CSS GLOBAL**************************************/
/*********************************************************************************************/
button.validationButton {
	color: #fff;
	border-radius: 0;
	padding: 10px 40px 10px 40px;
	box-shadow: none;
	text-transform: capitalize;
	font-family: Century Gothic;
	font-size: 1em;
}
button.validationButtonSmall {
	color: #fff;
	border-radius: 0;
	padding: 5px 15px;
	box-shadow: none;
	text-transform: capitalize;
	font-family: Century Gothic;
	font-size: 0.7em;
}
.MuiButton-contained.Mui-disabled {
	color: #b5b5b5;
	box-shadow: none;
	background-color: rgb(230 221 220);
}
.boutonCentrer {
	margin-top: 5%;
	text-align: center;
}

.padding {
	padding-top: 10%;
}

.logoHaxio {
	width: 150px;
	display: block;
	padding-top: 1%;
	padding-left: 1%;
	cursor: pointer;
}
@media only screen and (max-width: 481px) {
	.logoHaxio {
		width: 150px;
		display: block;
		margin-left: 10px;
		padding-top: 25px;
		padding-left: 0%;
		cursor: pointer;

	}
}
.MuiRadio-root.haxioRadio {
	color: #c77d00;
}
/************************************************************************************************/
/***********************************************CSS ANIMATION***********************************/
/*********************************************************************************************/
@-webkit-keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}
@keyframes puff-in-center {
	0% {
		-webkit-transform: scale(2);
		transform: scale(2);
		-webkit-filter: blur(4px);
		filter: blur(4px);
		opacity: 0;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

.puff-in-center {
	-webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
	animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
