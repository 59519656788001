footer {
	bottom: 0;
	position: fixed;
	width: 100vw;
	background-color: #585858;
	height: 40px;
	color: white;
	text-align: center;
	border-top: 1px solid #707070;
	line-height: 40px;
}


.footer-link{
	cursor: pointer;
}