.heading {
	margin: 0 auto;
	max-width: 55%;
}
@media only screen and (max-width: 480px) {
	.heading {
		margin: 0 auto;
		max-width: 100%;
	}
}
