@media only screen and (max-width: 480px) {
	.form-signin {
		padding: 20px;
		margin: 30% auto 0 auto;
		width: 70%;
		height: 65%;
		background: #ffffff;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
	}

	.fondDroite {
		background-image: url(/images/fondLogin-mobile.jpg);
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

@media only screen and (min-height: 700px) {
	.form-signin {
		padding: 20px;
		margin: 30% auto 0 auto;
		width: 70%;
		height: 50%;
		background: #ffffff;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (min-width: 481px) {
	.form-signin {
		padding: 20px;
		margin: 20% auto;
		width: 20%;
		height: 40%;
		background: #ffffff;
		border-radius: 6px;
		min-width: 300px;
		min-height: 450px	;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
	}
	.fondDroite {
		background-color: #f7eceb;
	}
}
.versionNumber {
	color: #c7c7c7;
	padding: 5px;
	bottom: 0;
	right: 0;
	position: absolute;
}
.form-signin {
	.form-control {
		padding: 10px;
		font-size: 16px;
		width: 70%;
		display:flex;
		flex-direction: column;
	}
	h4 {
		text-align: center;
		margin-top:5px;
		margin-bottom: 5px;
	}
	.logo {
		width: 150px;
		display: block;
		margin: 0px 15px 15px 15px;
		padding-top: 10%;
	}
}

.form-signin .libelle {
	font-size: 13px;
	float: left;
	font-weight: bold;
}
.form-signin input {
	border: 1px solid lightgrey;
	border-radius: 3px;
	padding: 5px;
}
.form-signin input:focus {
	border: 1px solid #c77d00;
	outline: #c77d00;
}
.fondGaucheDesktop {
	background-image: url(/images/fondLogin-desktop.jpg);
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
}


.forgottenPassword{
	width: 100%;
	margin-top: 50px;
	text-align: center;
	font-family: 'Century Gothic';
	text-decoration: underline;
	cursor: pointer;
}

.cgu-label {
	font-size: 10px;
	margin-left: 5px;
	a {
		text-decoration: underline;
	}
}
.form-control-row {
	display: flex;
	padding: 10px;
	width: 70%;
}

.dialog-cgu {
	font-family: -apple-system, BlinkMacSystemFont, 'Century Gothic Regular' !important;
	.dialog-title > h2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: -apple-system, BlinkMacSystemFont, 'Century Gothic Regular' !important;
	}
	.dialog-title > h2 > div {
		display: flex;
		align-items: center;
	}
	.dialong-cgu-content {
		text-align: justify !important;
		font-family: -apple-system, BlinkMacSystemFont, 'Century Gothic Regular' !important;
	}
	.icon {
		color: back !important;
		padding: 0;
	}
}