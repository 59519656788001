.containerListe {
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(/images/fondcarre.png);
	background-size: 300px;
	background-position: bottom -5% right -5%;
	min-height: 85vh;
}

.containerListe p {
	color: #919191;
	text-align: center;
	margin: 1% 0 5% 0;
	font-size: 1em;
}

.containerListe h1 {
	margin: 5%;
}

// .containerQuestionnaire {
// 	width: 70%;
// 	margin: auto;
// }

@media only screen and (max-width: 480px) {
	.containerListe {
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(/images/fondcarre.png);
		background-size: 300px;
		background-position: bottom 30% right -100%;
		min-height: 100vh;
	}

	.containerListe p {
		margin: 5%;
		padding-bottom: 5%;
		font-size: 11pt;
	}

	.containerListe h1 {
		padding-top: 5%;
	}
	.containerQuestionnaire {
		width: 100%;
		margin: auto;
	}
}
