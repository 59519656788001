

.rgpd-body-wrapper{
	position: relative;
	width: 100%;
	height: 100%;
	margin-bottom: 10%;
}

.rgpd-body-wrapper h2{
text-align: left;
}

.rgpd-body-wrapper h3{
	text-align: left;
}

.rgpd-body-wrapper p{
	text-align: left;
}
	

.rgpd-body-wrapper ul{
	text-align: left;
}
	






@media only screen and (max-width: 480px) {
	.fondRgpd {
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(/images/fondcarre.png);
		background-size: 300px;
		background-position: bottom -5% right -5%;
	}
}
