body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Century Gothic Regular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	height: 100vh;
}
