.fondRemerciement {
	background-color: #f7eceb;
	min-height:100%;
	padding-bottom: 10%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(/images/fondcarre.png);
	background-size: 300px;
	background-position: bottom -10% right 0;
}

.fondRemerciement h1 {
	padding-top: 5%;
}

.centerText {
	text-align: center;
}

@media only screen and (max-width: 480px) {
	.fondRemerciement {
		background-color: #f7eceb;
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(/images/fondcarre.png);
		background-size: 300px;
		background-position: bottom -10% right -130%;
	}
	.centerText {
		padding-top: 5%;
	}
}
